























import { Vue, Component, Prop } from "vue-property-decorator";
import { Radio, RadioGroup } from "element-ui";
@Component({
    components: {
        "el-radio-group": RadioGroup,
        "el-radio": Radio
    }
})
export default class FormItemRadio extends Vue {
    @Prop({
        type: [String, Number, Object],
        required: false,
        default: () => ({})
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    options: object = []; //radio项参数
    val: string = ""; //RadioGroup 绑定值

    // 钩子，选中值发生变化时触发
    change(data: any) {
        this.$emit("onchange", data);
    }

    getOption() {
        const getopt = this.$api.apiList[this.formItemOpt.apikey];
        const opt = getopt();
        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers
        })
            .then((res: any) => {
                this.options = res.data[this.formItemOpt.apiResKey];
            })
            .catch((res: any) => {});
    }

    mounted() {
        if (typeof this.value != "string") {
            this.val = String(this.value);
        } else {
            this.val = this.value;
        }

        if (!this.formItemOpt.children) {
            this.getOption();
        } else {
            this.options = this.formItemOpt.children;
        }

        console.log(this.options);
        
    }
}
